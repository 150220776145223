import { gql } from '@apollo/client';

export const ASSESSMENT_QUERY = gql`
    query Assessment($assessmentUid: String!) {
        assessment(assessmentUid: $assessmentUid) {
        uid
        isLocked
        name
        contextText
        introText
        preQuestionText
        questions {
            uid
            text
            questionType
            ordinal
            isOptional
            isSensitive
            answers {
            uid
            text
            }
            conditions {
            dependsOnQuestion {
                uid
                questionType
                ordinal
            }
            dependsOnAnswer {
                uid
            }
            isNegated
            }
        }
        }
    }
  `;

export const ME_QUERY = gql`
    query {
        me {
            accountType
        }
    }
`;

export const ASSESSMENTS_QUERY = gql`
    query($pdtUid: String) {
        assessments(pdtUid: $pdtUid) {
            uid,
            name,
        }
    }
`;

export const ASSIGNMENTS_QUERY = gql`
query($pdtUid: String, $onlyVisible: Boolean!, $clientType: String!) {
    assignments(onlyVisible: $onlyVisible, clientType: $clientType, pdtUid: $pdtUid) {
        uid,
        pdt {
            uid,
            name
        },
        assessment {
            name,
            uid
        },
        dueDate,
        typeOfAssignment,
        completeTime,
        canSkipQuestions,
        aboutUser {
            firstName,
            language
        }
    }
}
`;

export const CHECK_ELIGIBILITY_QUERY = gql`
    query eligibilityStatus(
        $pdtUid: String!, 
        $firstName: String!, 
        $lastName: String!,
        $dateOfBirth: DateTime!,
        $postalCode: String!,
        $organizationSlug: String!,
        $productId: Int!,
        $employeeId: String,
        $uid: String,
        ) {
            eligibilityStatus(
                pdtUid: $pdtUid,
                firstName: $firstName,
                lastName: $lastName,
                dateOfBirth: $dateOfBirth,
                postalCode: $postalCode,
                organizationSlug: $organizationSlug,
                productId: $productId,
                employeeId: $employeeId,
                uid: $uid,
            ) {
                isEligible
            }
        }
`;

export const ELIGIBILITY_CONFIGURATION_QUERY = gql`
    query eligibilityConfiguration($organizationSlug: String!, $pdtUid: String!) {
        eligibilityConfiguration(organizationSlug: $organizationSlug, pdtUid: $pdtUid) {
            isValid,
            hasEmployeeId,
            hasAddressField,
            employeeIdFieldRename
        }
    }
`;
