import React, { useContext, useState } from 'react';
import { Box, Text } from '@bighealth/react-limbix-ui';
import { TextField } from '@material-ui/core';

import { useMutation } from '@apollo/client';

import { useParams } from 'react-router-dom';

import { isValidPhoneNumber } from 'react-phone-number-input';

import { useTranslation } from 'react-i18next';

import Styled from './PatientInfo.styles';

import { CTAButton } from '../../components/CTAButton/CTAButton';
import { Titles } from '../../components/Titles/Titles';
import { DataSharingInfo } from '../../components/DataSharingInfo/DataSharingInfo';
import { OnboardingFlowContext } from '../../OnboardingFlowContext';
import { WhyDoWeNeedThisInfo } from '../../components/OnboardingPopover/popoverContent/WhyDoWeNeedThisInfo';
import {
  TermsAndConditionsAcknowledgements,
} from '../../components/TermsAndConditionsAcknowledgements/TermsAndConditionsAcknowledgements';
import validatePatientInfoForm from './validatePatientInfoForm';
import { StatePicker } from '../../components/StatePicker/StatePicker';

import { getPdtUid } from '../../utils/productHelpers';

import { OnboardingParams } from '../../types';

import { ErrorText } from '../../components/ErrorText/ErrorText';

import { PhoneNumberInput } from '../../components/PhoneNumberInput/PhoneNumberInput';

import { isEmailError } from '../../utils/errorHelpers';

import { validateEmail } from '@/utils/stringValidators';

import { convertDOBToDateString } from '@/utils/stringUtils';
import { ENROLL_PATIENT } from '@/apollo/mutations';
import { identifyUser } from '@/utils/heap';
import { EN } from '@/utils/constants';

export const PatientInfo: React.FC = () => {
  const {
    next,
    updateFormData,
    onboardingFormData,
    eligibilityFormCustomization,
    uid,
  } = useContext(OnboardingFlowContext);
  const { t, i18n } = useTranslation();
  const { product, slug } = useParams<OnboardingParams>();
  const [popoverOpen, setPopoverOpen] = useState(false);
  const formValid = validatePatientInfoForm(onboardingFormData, eligibilityFormCustomization, product);

  // validate each text field individually. used for error states and helper text...
  const [patientEmailFieldError, setPatientEmailFieldError] = useState(false);
  const [patientPhoneNumberFieldError, setPatientPhoneNumberFieldError] = useState(false);
  const [patientAddressFieldError, setPatientAddressFieldError] = useState(false);
  const [patientCityFieldError, setPatientCityFieldError] = useState(false);
  const [generalErrorMessage, setGeneralErrorMessage] = useState(undefined);

  const [enrollPatient, { loading }] = useMutation(ENROLL_PATIENT);

  // only show address field if the organization has the address field enabled
  const { showAddressField } = eligibilityFormCustomization;

  const handlePatientInfo = async () => {
    setGeneralErrorMessage(undefined);
    // if this value is caregiver we need to move on to the next part of the flow before we enroll patient
    if (onboardingFormData.personEnrolling === 'caregiver') {
      next();
      return;
    }

    try {
      const currentISODate = new Date().toISOString();
      const result = await enrollPatient({
        variables: {
          address: onboardingFormData.patientStreetAddress,
          city: onboardingFormData.patientCity,
          dateOfBirth: convertDOBToDateString(onboardingFormData.patientDateOfBirth),
          email: onboardingFormData.patientEmail,
          emergencyContactEmail: onboardingFormData.caregiverEmail,
          emergencyContactName: onboardingFormData.caregiverFirstName,
          emergencyContactPhone: onboardingFormData.caregiverPhoneNumber,
          firstName: onboardingFormData.patientFirstName,
          lastName: onboardingFormData.patientLastName,
          organizationSlug: slug,
          pdtUid: getPdtUid(product),
          phone: onboardingFormData.patientPhoneNumber,
          postalCode: onboardingFormData.patientZipCode,
          state: onboardingFormData.patientState,
          uid,
          safetyAcknowledgedAt: !onboardingFormData.safetyAcknowledgement ? null : currentISODate,
          termsAndPrivacyAcknowledgedAt: onboardingFormData.policyAndTermsAcknowledgement && currentISODate,
          language: i18n.language.toUpperCase().split('-')[0] || EN,
        },
      });

      if (result?.data?.enrollDirectAccessPatient?.success === true) {
        // creating the user in our system was successful
        identifyUser(onboardingFormData.patientEmail);
        next();
      } else if (isEmailError(result?.errors)) {
        setPatientEmailFieldError(true);
      } else {
        // creating the user in our system was UNsuccessful
        console.warn('we were not able to successfully create a profile for this user: ', result);
        setGeneralErrorMessage(t('coverage.errors.generic_error'));
      }
    } catch (e) {
      // There was a server error
      console.log('there was a server error trying to create this user', e);
      setGeneralErrorMessage(t('coverage.errors.generic_error'));
    }
  };

  return (
    <>
      <Titles
        title={t(`coverage.${product}.${onboardingFormData.personEnrolling}.patient_title`)}
      />
      <Styled.HoverContainer
        onClick={() => setPopoverOpen(!popoverOpen)}
      >
        <Text
          fontSize="18px"
          fontWeight="400"
          underlined
          lineHeight="24.30px"
        >
          {t(`coverage.${product}.${onboardingFormData.personEnrolling}.patient_subtitle`)}
        </Text>
      </Styled.HoverContainer>
      <Text
        color="#363334"
        fontSize="24px"
        fontWeight="700"
      >
        {t(`coverage.${product}.${onboardingFormData.personEnrolling}.patient_email_title`)}
      </Text>
      <Text
        fontSize="18px"
        fontWeight="400"
        lineHeight="24.30px"
      >
        {t(`coverage.${product}.${onboardingFormData.personEnrolling}.patient_email_subtitle`)}
      </Text>

      <TextField
        className="input-field"
        variant="outlined"
        margin="normal"
        fullWidth
        required
        error={patientEmailFieldError}
        helperText={patientEmailFieldError ? t('coverage.errors.email_error') : ''}
        label={t(`coverage.${product}.${onboardingFormData.personEnrolling}.email_label`)}
        id="patientEmail"
        inputProps={{
          placeholder: t(`coverage.${product}.${onboardingFormData.personEnrolling}.email_label`),
        }}
        value={onboardingFormData.patientEmail}
        onChange={(e) => {
          setPatientEmailFieldError(false);
          updateFormData({ patientEmail: e.target.value });
        }}
        onBlur={(e) => {
          setPatientEmailFieldError(!validateEmail(e.target.value));
        }}
      />
      <Text
        color="#363334"
        fontSize="24px"
        fontWeight="700"
      >
        {t(`coverage.${product}.${onboardingFormData.personEnrolling}.patient_info_title`)}
      </Text>
      <PhoneNumberInput
        placeholder={t(`coverage.${product}.${onboardingFormData.personEnrolling}.phone_label`)}
        value={onboardingFormData.patientPhoneNumber}
        id="patientPhoneNumber"
        onChange={(value) => {
          setPatientPhoneNumberFieldError(false);
          updateFormData({ patientPhoneNumber: value || '' });
        }}
        onBlur={() => {
          setPatientPhoneNumberFieldError(!isValidPhoneNumber(onboardingFormData.patientPhoneNumber));
        }}
        error={patientPhoneNumberFieldError}
        helperText={patientPhoneNumberFieldError ? t('coverage.errors.phone_error') : ''}
      />
      {showAddressField
        && (
          <>
            <TextField
              className="input-field"
              variant="outlined"
              margin="normal"
              fullWidth
              required
              error={patientAddressFieldError}
              helperText={patientAddressFieldError ? t('coverage.errors.street_error') : ''}
              label={t(`coverage.${product}.${onboardingFormData.personEnrolling}.street_label`)}
              id="patientStreetAddress"
              inputProps={{
                placeholder: t(`coverage.${product}.${onboardingFormData.personEnrolling}.street_label`),
              }}
              value={onboardingFormData.patientStreetAddress}
              onChange={(e) => {
                setPatientAddressFieldError(false);
                updateFormData({ patientStreetAddress: e.target.value });
              }}
              onBlur={(e) => {
                setPatientAddressFieldError(e.target.value === '');
              }}
            />
            <TextField
              className="input-field"
              variant="outlined"
              margin="normal"
              fullWidth
              required
              error={patientCityFieldError}
              helperText={patientCityFieldError ? t('coverage.errors.city_error') : ''}
              label={t(`coverage.${product}.${onboardingFormData.personEnrolling}.city_label`)}
              id="patientCity"
              inputProps={{
                placeholder: t(`coverage.${product}.${onboardingFormData.personEnrolling}.city_label`),
              }}
              value={onboardingFormData.patientCity}
              onChange={(e) => {
                setPatientCityFieldError(false);
                updateFormData({ patientCity: e.target.value });
              }}
              onBlur={(e) => {
                setPatientCityFieldError(e.target.value === '');
              }}
            />
            <StatePicker
              value={onboardingFormData.patientState}
              onChange={(e) => updateFormData({ patientState: e.target.value })}
              label={t(`coverage.${product}.${onboardingFormData.personEnrolling}.state_label`)}
            />
          </>
        )}
      <div
        style={{ marginBottom: '32px' }}
      />
      {onboardingFormData.personEnrolling === 'patient' && (
        <TermsAndConditionsAcknowledgements
          safetyAcknowledgement={onboardingFormData.safetyAcknowledgement}
          safetyAcknowledgementLabel={t('coverage.safety_acknowledgement_label')}
          policyAndTermsAcknowledgement={onboardingFormData.policyAndTermsAcknowledgement}
          onSelect={(data) => updateFormData(data)}
        />
      )}

      {generalErrorMessage && (
        <ErrorText error={generalErrorMessage} />
      )}

      <CTAButton
        text={t('coverage.submit')}
        handleClick={handlePatientInfo}
        isDisabled={formValid === false}
        showLoading={loading}
      />
      <Box style={{ display: 'flex', justifyContent: 'center' }}>
        <DataSharingInfo />
      </Box>
      <WhyDoWeNeedThisInfo
        popoverOpen={popoverOpen}
        setPopoverOpen={setPopoverOpen}
        personEnrolling={onboardingFormData.personEnrolling}
      />
    </>
  );
};
